export const Accordion = () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AccountDeliveryAddresses = () => import('../../components/AccountDeliveryAddresses.vue' /* webpackChunkName: "components/account-delivery-addresses" */).then(c => wrapFunctional(c.default || c))
export const AccountOrderHistory = () => import('../../components/AccountOrderHistory.vue' /* webpackChunkName: "components/account-order-history" */).then(c => wrapFunctional(c.default || c))
export const AccountPaymentDetails = () => import('../../components/AccountPaymentDetails.vue' /* webpackChunkName: "components/account-payment-details" */).then(c => wrapFunctional(c.default || c))
export const AccountProfilePassword = () => import('../../components/AccountProfilePassword.vue' /* webpackChunkName: "components/account-profile-password" */).then(c => wrapFunctional(c.default || c))
export const AccountSavedQuotes = () => import('../../components/AccountSavedQuotes.vue' /* webpackChunkName: "components/account-saved-quotes" */).then(c => wrapFunctional(c.default || c))
export const AddToWishlist = () => import('../../components/AddToWishlist.vue' /* webpackChunkName: "components/add-to-wishlist" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const Badges = () => import('../../components/Badges.vue' /* webpackChunkName: "components/badges" */).then(c => wrapFunctional(c.default || c))
export const BenefitsBar = () => import('../../components/BenefitsBar.vue' /* webpackChunkName: "components/benefits-bar" */).then(c => wrapFunctional(c.default || c))
export const BottomNavigation = () => import('../../components/BottomNavigation.vue' /* webpackChunkName: "components/bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumbs = () => import('../../components/Breadcrumbs.vue' /* webpackChunkName: "components/breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const BuildContents = () => import('../../components/BuildContents.vue' /* webpackChunkName: "components/build-contents" */).then(c => wrapFunctional(c.default || c))
export const BuilderContents = () => import('../../components/BuilderContents.vue' /* webpackChunkName: "components/builder-contents" */).then(c => wrapFunctional(c.default || c))
export const CallToAction = () => import('../../components/CallToAction.vue' /* webpackChunkName: "components/call-to-action" */).then(c => wrapFunctional(c.default || c))
export const CardSection = () => import('../../components/CardSection.vue' /* webpackChunkName: "components/card-section" */).then(c => wrapFunctional(c.default || c))
export const Cards = () => import('../../components/Cards.vue' /* webpackChunkName: "components/cards" */).then(c => wrapFunctional(c.default || c))
export const CategoryTest = () => import('../../components/CategoryTest.vue' /* webpackChunkName: "components/category-test" */).then(c => wrapFunctional(c.default || c))
export const CheckoutFooter = () => import('../../components/CheckoutFooter.vue' /* webpackChunkName: "components/checkout-footer" */).then(c => wrapFunctional(c.default || c))
export const CheckoutHeader = () => import('../../components/CheckoutHeader.vue' /* webpackChunkName: "components/checkout-header" */).then(c => wrapFunctional(c.default || c))
export const ConsultationForm = () => import('../../components/ConsultationForm.vue' /* webpackChunkName: "components/consultation-form" */).then(c => wrapFunctional(c.default || c))
export const ContactDetailsForm = () => import('../../components/ContactDetailsForm.vue' /* webpackChunkName: "components/contact-details-form" */).then(c => wrapFunctional(c.default || c))
export const ContentBlock = () => import('../../components/ContentBlock.vue' /* webpackChunkName: "components/content-block" */).then(c => wrapFunctional(c.default || c))
export const ContentBlocks = () => import('../../components/ContentBlocks.vue' /* webpackChunkName: "components/content-blocks" */).then(c => wrapFunctional(c.default || c))
export const Copy = () => import('../../components/Copy.vue' /* webpackChunkName: "components/copy" */).then(c => wrapFunctional(c.default || c))
export const CouponCode = () => import('../../components/CouponCode.vue' /* webpackChunkName: "components/coupon-code" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelector = () => import('../../components/CurrencySelector.vue' /* webpackChunkName: "components/currency-selector" */).then(c => wrapFunctional(c.default || c))
export const Downloads = () => import('../../components/Downloads.vue' /* webpackChunkName: "components/downloads" */).then(c => wrapFunctional(c.default || c))
export const FAQsCategories = () => import('../../components/FAQs-Categories.vue' /* webpackChunkName: "components/f-a-qs-categories" */).then(c => wrapFunctional(c.default || c))
export const FAQs = () => import('../../components/FAQs.vue' /* webpackChunkName: "components/f-a-qs" */).then(c => wrapFunctional(c.default || c))
export const Favourite = () => import('../../components/Favourite.vue' /* webpackChunkName: "components/favourite" */).then(c => wrapFunctional(c.default || c))
export const FilterSorting = () => import('../../components/FilterSorting.vue' /* webpackChunkName: "components/filter-sorting" */).then(c => wrapFunctional(c.default || c))
export const FormInputNumber = () => import('../../components/FormInputNumber.vue' /* webpackChunkName: "components/form-input-number" */).then(c => wrapFunctional(c.default || c))
export const FullImageCardBanner = () => import('../../components/FullImageCardBanner.vue' /* webpackChunkName: "components/full-image-card-banner" */).then(c => wrapFunctional(c.default || c))
export const HTMLContent = () => import('../../components/HTMLContent.vue' /* webpackChunkName: "components/h-t-m-l-content" */).then(c => wrapFunctional(c.default || c))
export const HeaderLogo = () => import('../../components/HeaderLogo.vue' /* webpackChunkName: "components/header-logo" */).then(c => wrapFunctional(c.default || c))
export const HeroBanner = () => import('../../components/HeroBanner.vue' /* webpackChunkName: "components/hero-banner" */).then(c => wrapFunctional(c.default || c))
export const HeroSection = () => import('../../components/HeroSection.vue' /* webpackChunkName: "components/hero-section" */).then(c => wrapFunctional(c.default || c))
export const IconBar = () => import('../../components/IconBar.vue' /* webpackChunkName: "components/icon-bar" */).then(c => wrapFunctional(c.default || c))
export const ImageCopy = () => import('../../components/ImageCopy.vue' /* webpackChunkName: "components/image-copy" */).then(c => wrapFunctional(c.default || c))
export const ImageWave = () => import('../../components/ImageWave.vue' /* webpackChunkName: "components/image-wave" */).then(c => wrapFunctional(c.default || c))
export const InstagramFeed = () => import('../../components/InstagramFeed.vue' /* webpackChunkName: "components/instagram-feed" */).then(c => wrapFunctional(c.default || c))
export const IntersectionalBanner = () => import('../../components/IntersectionalBanner.vue' /* webpackChunkName: "components/intersectional-banner" */).then(c => wrapFunctional(c.default || c))
export const KitchensResults = () => import('../../components/KitchensResults.vue' /* webpackChunkName: "components/kitchens-results" */).then(c => wrapFunctional(c.default || c))
export const MobileStoreBanner = () => import('../../components/MobileStoreBanner.vue' /* webpackChunkName: "components/mobile-store-banner" */).then(c => wrapFunctional(c.default || c))
export const NarrowBanner = () => import('../../components/NarrowBanner.vue' /* webpackChunkName: "components/narrow-banner" */).then(c => wrapFunctional(c.default || c))
export const NewProducts = () => import('../../components/NewProducts.vue' /* webpackChunkName: "components/new-products" */).then(c => wrapFunctional(c.default || c))
export const NewSiteNotice = () => import('../../components/NewSiteNotice.vue' /* webpackChunkName: "components/new-site-notice" */).then(c => wrapFunctional(c.default || c))
export const NewsletterSignup = () => import('../../components/NewsletterSignup.vue' /* webpackChunkName: "components/newsletter-signup" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const OnlineCheck = () => import('../../components/OnlineCheck.vue' /* webpackChunkName: "components/online-check" */).then(c => wrapFunctional(c.default || c))
export const OtherResults = () => import('../../components/OtherResults.vue' /* webpackChunkName: "components/other-results" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ProductCounter = () => import('../../components/ProductCounter.vue' /* webpackChunkName: "components/product-counter" */).then(c => wrapFunctional(c.default || c))
export const ProductRow = () => import('../../components/ProductRow.vue' /* webpackChunkName: "components/product-row" */).then(c => wrapFunctional(c.default || c))
export const ProductRowDetails = () => import('../../components/ProductRowDetails.vue' /* webpackChunkName: "components/product-row-details" */).then(c => wrapFunctional(c.default || c))
export const RangeSlider = () => import('../../components/RangeSlider.vue' /* webpackChunkName: "components/range-slider" */).then(c => wrapFunctional(c.default || c))
export const RangeSliderItem = () => import('../../components/RangeSliderItem.vue' /* webpackChunkName: "components/range-slider-item" */).then(c => wrapFunctional(c.default || c))
export const Ranges = () => import('../../components/Ranges.vue' /* webpackChunkName: "components/ranges" */).then(c => wrapFunctional(c.default || c))
export const Related = () => import('../../components/Related.vue' /* webpackChunkName: "components/related" */).then(c => wrapFunctional(c.default || c))
export const SearchResults = () => import('../../components/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c))
export const Sharing = () => import('../../components/Sharing.vue' /* webpackChunkName: "components/sharing" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SimpleBanner = () => import('../../components/SimpleBanner.vue' /* webpackChunkName: "components/simple-banner" */).then(c => wrapFunctional(c.default || c))
export const SlickCards = () => import('../../components/SlickCards.vue' /* webpackChunkName: "components/slick-cards" */).then(c => wrapFunctional(c.default || c))
export const SlickCarousel = () => import('../../components/SlickCarousel.vue' /* webpackChunkName: "components/slick-carousel" */).then(c => wrapFunctional(c.default || c))
export const StepProgress = () => import('../../components/StepProgress.vue' /* webpackChunkName: "components/step-progress" */).then(c => wrapFunctional(c.default || c))
export const StoreSwitcher = () => import('../../components/StoreSwitcher.vue' /* webpackChunkName: "components/store-switcher" */).then(c => wrapFunctional(c.default || c))
export const Team = () => import('../../components/Team.vue' /* webpackChunkName: "components/team" */).then(c => wrapFunctional(c.default || c))
export const TitleDescription = () => import('../../components/TitleDescription.vue' /* webpackChunkName: "components/title-description" */).then(c => wrapFunctional(c.default || c))
export const TrustPilot = () => import('../../components/TrustPilot.vue' /* webpackChunkName: "components/trust-pilot" */).then(c => wrapFunctional(c.default || c))
export const TrustPilotFeatured = () => import('../../components/TrustPilotFeatured.vue' /* webpackChunkName: "components/trust-pilot-featured" */).then(c => wrapFunctional(c.default || c))
export const UserAddressDetails = () => import('../../components/UserAddressDetails.vue' /* webpackChunkName: "components/user-address-details" */).then(c => wrapFunctional(c.default || c))
export const Video = () => import('../../components/Video.vue' /* webpackChunkName: "components/video" */).then(c => wrapFunctional(c.default || c))
export const VideoCopySplit = () => import('../../components/VideoCopySplit.vue' /* webpackChunkName: "components/video-copy-split" */).then(c => wrapFunctional(c.default || c))
export const ZoomCards = () => import('../../components/ZoomCards.vue' /* webpackChunkName: "components/zoom-cards" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelectorCurrenciesModal = () => import('../../components/CurrencySelector/CurrenciesModal.vue' /* webpackChunkName: "components/currency-selector-currencies-modal" */).then(c => wrapFunctional(c.default || c))
export const GeneralIconSprite = () => import('../../components/General/IconSprite.vue' /* webpackChunkName: "components/general-icon-sprite" */).then(c => wrapFunctional(c.default || c))
export const GeneralSvgImage = () => import('../../components/General/SvgImage.vue' /* webpackChunkName: "components/general-svg-image" */).then(c => wrapFunctional(c.default || c))
export const SchemaBreadcrumbsSchema = () => import('../../components/Schema/BreadcrumbsSchema.vue' /* webpackChunkName: "components/schema-breadcrumbs-schema" */).then(c => wrapFunctional(c.default || c))
export const SchemaFAQSchema = () => import('../../components/Schema/FAQSchema.vue' /* webpackChunkName: "components/schema-f-a-q-schema" */).then(c => wrapFunctional(c.default || c))
export const SchemaHowToGuidesSchema = () => import('../../components/Schema/HowToGuidesSchema.vue' /* webpackChunkName: "components/schema-how-to-guides-schema" */).then(c => wrapFunctional(c.default || c))
export const SchemaLocalBusiness = () => import('../../components/Schema/LocalBusiness.vue' /* webpackChunkName: "components/schema-local-business" */).then(c => wrapFunctional(c.default || c))
export const SchemaProductSchema = () => import('../../components/Schema/ProductSchema.vue' /* webpackChunkName: "components/schema-product-schema" */).then(c => wrapFunctional(c.default || c))
export const SchemaSearchSchema = () => import('../../components/Schema/SearchSchema.vue' /* webpackChunkName: "components/schema-search-schema" */).then(c => wrapFunctional(c.default || c))
export const SkeletonLoader = () => import('../../components/SkeletonLoader/index.vue' /* webpackChunkName: "components/skeleton-loader" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/TopBar/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const TopBarCheckStoresAndCurrencyGql = () => import('../../components/TopBar/checkStoresAndCurrency.gql.ts' /* webpackChunkName: "components/top-bar-check-stores-and-currency-gql" */).then(c => wrapFunctional(c.default || c))
export const TopBarUseTopBar = () => import('../../components/TopBar/useTopBar.ts' /* webpackChunkName: "components/top-bar-use-top-bar" */).then(c => wrapFunctional(c.default || c))
export const StoreSwitcherStoresModal = () => import('../../components/StoreSwitcher/StoresModal.vue' /* webpackChunkName: "components/store-switcher-stores-modal" */).then(c => wrapFunctional(c.default || c))
export const UtilsLoadWhenVisible = () => import('../../components/utils/LoadWhenVisible.vue' /* webpackChunkName: "components/utils-load-when-visible" */).then(c => wrapFunctional(c.default || c))
export const CurrencyDataMock = () => import('../../components/CurrencySelector/__tests__/currencyData.mock.ts' /* webpackChunkName: "components/currency-data-mock" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigation = () => import('../../components/Header/Navigation/HeaderNavigation.vue' /* webpackChunkName: "components/header-navigation" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationItem = () => import('../../components/Header/Navigation/HeaderNavigationItem.vue' /* webpackChunkName: "components/header-navigation-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderNavigationSubcategories = () => import('../../components/Header/Navigation/HeaderNavigationSubcategories.vue' /* webpackChunkName: "components/header-navigation-subcategories" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchBar = () => import('../../components/Header/SearchBar/SearchBar.vue' /* webpackChunkName: "components/header-search-bar" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchResults = () => import('../../components/Header/SearchBar/SearchResults.vue' /* webpackChunkName: "components/header-search-results" */).then(c => wrapFunctional(c.default || c))
export const StoreSwitcherTestsAvailableStoresMock = () => import('../../components/StoreSwitcher/__tests__/availableStores.mock.ts' /* webpackChunkName: "components/store-switcher-tests-available-stores-mock" */).then(c => wrapFunctional(c.default || c))
export const DirectivesClickOutsideDirective = () => import('../../components/directives/click-outside/click-outside-directive.ts' /* webpackChunkName: "components/directives-click-outside-directive" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
