
import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const getProductPricesCommand = {
  execute: async (context: VsfContext, params) => {
    Logger.debug('[Winter CMS]: Get Product Prices');
    
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    // const data = await context.$magento.api.getProductPrices(params);
    // Logger.debug(params);

    const crypto = require('crypto');
    // Convert payload to a string if it isn't already.
    const payloadStr = typeof params === 'string' ? params : JSON.stringify(params);
    // Use MD5 to create a hash. (MD5 is fast and fine for non-cryptographic purposes.)
    const hash = crypto.createHash('md5').update(payloadStr).digest('hex');
    // Logger.debug(hash);

    const response = await fetch('/api/magento/getProductPrices?hash='+hash, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify([params])
    });

    const data = await response.json();
    // Logger.debug(data);
    return data;
  }
};